import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ReactGa from "react-ga4";

import { About } from "./components/About";
import { Article } from "./components/Article";
import { Articles } from "./components/Articles";
import { CreateArticle } from "./components/CreateArticle";
import { CreateUser } from "./components/CreateUser";
import { EditArticle } from "./components/EditArticle";
import { EditUser } from "./components/EditUser";
import { Footer } from "./components/Footer";
import { HomeTitles } from "./components/HomeTitles";
import { Login } from "./components/Login";
import { Logout } from "./components/Logout";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { Search } from "./components/Search";
import { Shortlinks } from "./components/Shortlinks";
import { Shortlink } from "./components/Shortlink";
import { StyledHeader } from "./components/Header";
import { Submissions } from "./components/Submissions";
import { TopicPage } from "./components/TopicPage";
import { User } from "./components/User";
import { UserContext } from "./UserContext";
import { Users } from "./components/Users";
import { TermsOfService } from "./TermsOfService";
import { getCookieConsentValue } from "react-cookie-consent";

const VIEWER_QUERY = gql`
query Viewer {
  currentUser {
    id
    email
    role 
  }
}
`

const StyledContent = styled.main`
  flex: 1;
  margin: 0 16px;
`;

const allowedLetters = "b-df-hj-np-tv-z";
const allowedNumbers = "124-9";
const shortLinkMatcher = `([${allowedLetters}${allowedLetters.toUpperCase()}${allowedNumbers}]{3})`;

const doesNotExistRoute = () => 
    <Route 
      path="*">
        <h1>
          404
        </h1>
    </Route>

const App = () => {
  const { data, loading, error } = useQuery(VIEWER_QUERY);
  const currentUser = data ? data.currentUser : null;
  
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(undefined);
  useEffect(() => {
    new MutationObserver((_mutations) => {
      const hasCookieConsent = getCookieConsentValue() === "true";
      if (!hasCookieConsent) {
          return;
      }
        ReactGa.send({
            hitType: "pageview", page: document.location.pathname, title: document.title
        });
    }).observe(
      document.querySelector('title'),
      { subtree: true, characterData: true, childList: true }
    );
  }, []);

  useEffect(() => {
    if (!data)
      return
    
    const { currentUser } = data;

    if (!currentUser) {
      setIsAdmin(false);
      setIsEditor(false);
      setIsLoggedIn(false);
      setUserId(undefined);
      return
    }

    const loggedIn = currentUser && (currentUser.id) ? true : false;

    setIsAdmin(currentUser.role === 'admin');
    setIsEditor(currentUser.role === 'editor');
    setIsLoggedIn(loggedIn);
    setUserId(currentUser && currentUser.id);
  }, [data]);  

  if (loading)
    return null;

  if (error)
    console.log(JSON.stringify(error, null, 2));

  const redirectToHome = () => {document.location.pathname = '/';}
  const renderProfileOrHome = (paramsUserId, Component) => {
      const visitingOwn = userId === paramsUserId;
      return (visitingOwn) ? <Component /> : redirectToHome();
  }

  return (
    <UserContext.Provider value={{isAdmin, isLoggedIn, userId, role: currentUser?.role}}>
      <Router >
        <StyledHeader />
        <StyledContent>
            {
              isAdmin
              && <Switch>
                <Route exact path='/' component={HomeTitles} />
                <Route exact path='/about' component={About} />
                <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                <Route exact path='/terms-of-service' component={TermsOfService} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/shortlinks' component={Shortlinks} />
                <Route exact path="/topics/:slug" render={(props) => <TopicPage {...props}/>}/>
                <Route exact path='/search' component={Search} />
                <Route exact path={`/${shortLinkMatcher}`} render={(props) => <Shortlink {...props}/>} />
                <Route exact path='/articles' component={Articles} />
                <Route exact path={`/articles/create`} component={CreateArticle} />
                <Route exact path={`/articles/:articleId`} component={Article} />
                <Route exact path={`/articles/:articleId/edit`} component={EditArticle} />
                <Route exact path='/users' component={Users} />
                <Route exact path='/users/:userId' component={User} />
                <Route exact path={`/users/create`} component={CreateUser} />
                <Route exact path={`/users/:userId/edit`} component={EditUser} />
                {doesNotExistRoute()}
              </Switch>
            }
            {
              isEditor
              && <Switch>
                <Route exact path='/' component={HomeTitles} />
                <Route exact path='/about' component={About} />
                <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                <Route exact path='/terms-of-service' component={TermsOfService} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path="/topics/:slug" render={(props) => <TopicPage {...props}/>}/>
                <Route exact path='/search' component={Search} />
                <Route exact path={`/${shortLinkMatcher}`} render={(props) => <Shortlink {...props}/>} />
                <Route exact path='/articles' component={Articles} />
                <Route exact path={`/articles/create`} component={CreateArticle} />
                <Route exact path={`/articles/:articleId`} component={Article} />
                <Route exact path={`/articles/:articleId/edit`} component={EditArticle} />
                <Route exact path={`/users/:userId`} render={
                  (props) => renderProfileOrHome(props.match.params.userId, User)
                  } />
                <Route exact path={`/users/:userId/edit`} render={
                  (props) => renderProfileOrHome(props.match.params.userId, EditUser)
                } />
                {doesNotExistRoute()}
              </Switch>
            }
            {
              isLoggedIn && !isAdmin && !isEditor
              && <Switch>
                <Route exact path='/' component={HomeTitles} />
                <Route exact path='/about' component={About} />
                <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                <Route exact path='/terms-of-service' component={TermsOfService} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/search' component={Search} />
                <Route exact path={`/${shortLinkMatcher}`} render={(props) => <Shortlink {...props}/>} />
                <Route exact path="/topics/:slug" render={(props) => <TopicPage {...props}/>}/>
                <Route exact path={`/articles/:articleId`} component={Article} />
                <Route exact path={`/users/:userId`} render={
                  (props) => renderProfileOrHome(props.match.params.userId, User)
                  } />
                <Route exact path={`/users/:userId/edit`} render={
                  (props) => renderProfileOrHome(props.match.params.userId, EditUser)
                } />
                {doesNotExistRoute()}
              </Switch>
            }
            {
              !isLoggedIn 
              && <Switch>
                <Route exact path='/' component={HomeTitles} />
                <Route exact path='/about' component={About} />
                <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                <Route exact path='/terms-of-service' component={TermsOfService} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/search' component={Search} />
                <Route exact path={`/${shortLinkMatcher}`} render={(props) => <Shortlink {...props}/>} />
                <Route exact path='/submissions' component={Submissions} />
                <Route exact path='/login' component={Login} />
                <Route exact path="/topics/:slug" render={(props) => <TopicPage {...props}/>}/>
                <Route exact path={`/articles/:articleId`} component={Article} />
                {doesNotExistRoute()}
              </Switch>
            }
        </StyledContent>
        <Footer />
      </Router>
    </UserContext.Provider>
  );
}

export { App };
