import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

const CREATE_USER_MUTATION = gql`
mutation CreateUser($inputData: CreateUserInput!) {
  createUser(inputData: $inputData) {
    id
    email
  }
}
`;

export const CreateUser: any = (props: any) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");

    const options = {
        variables: {
            inputData: {
                email,
                password,
                role: role === "" ? undefined : role,
            },
        },
    }
    const [createUser] = useMutation(CREATE_USER_MUTATION, options);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        await createUser()
            .then(
                () => document.location.pathname =
                '/users'
            )
            .catch(
                (error) => console.log(JSON.stringify(error, null, 2))
            );
    };

    return (
        <form onSubmit={(e: any) => handleSubmit(e)}>
            <label>
            Email address:
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>

            <label>
            password:
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            <label>
            role:
            <select value={role} onChange={(e) => setRole(e.target.value)}>
                <option value=''> -- Select one -- </option>
                <option value='admin'>admin</option>
            </select>
            </label>

            <input type="submit" value="Submit" />
        </form>
    )
};
