import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { bodyContainerStyle } from '../styles';

export const ALL_TAGS_QUERY = gql`
query AllTags {
  allTags {
    edges {
      node {
        id
        name
      }
    }
  }
}
`;

const TagsSelect = (props: any) => {
    const { error, loading, data } = useQuery(ALL_TAGS_QUERY);
    const { setTags, tags } = props;

    if (loading)
        return null;
    if (error) {
        console.log(error, error && error.message);
        return <>Error</>;
    }

    const handleChange = (e: any) => {
        const options = e.target.options;
        
        const selectedValues: any = [];

        for (const option of options) 
            if (option.selected)
                selectedValues.push(option.value);

        // filter and map
        const selectedValuesTags = data?.allTags?.edges?.flatMap(
            ({ node }: any) => selectedValues.includes(node.id) ? [node] : []
        );

        setTags(selectedValuesTags);
    }

    const tagOptions = data?.allTags?.edges?.map(({ node }: any) => 
        <option value={parseInt(node.id)}  key={parseInt(node.id)}>
            {node.name.split('.')[1].toLowerCase()}
        </option>
    );
    const tagIds = tags.map((e: any) => e.id);

    return (
        <label>
            Tags:
            <div style={bodyContainerStyle} >
            <select
                name="tags"
                id="tags"
                multiple={true}
                onChange={(e: any) => handleChange(e)}
                value={tagIds}
            >
                {tagOptions}
            </select>
            </div>
        </label>
    )
}

export { TagsSelect }