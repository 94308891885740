import React from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';

const LOGOUT_MUTATION = gql`
    mutation LogoutUser {
        logOutUser {
            nextUrl
        }
    }
`;

const Logout = () => {
    const [logout] = useMutation(LOGOUT_MUTATION);
    const client = useApolloClient();

    logout()
        .then(() => {
            client.resetStore();
            document.location.pathname = '/';
        })
        .catch(
            (e:any) => {
                console.log(e);

                client.resetStore();
                document.location.pathname = '/';
            }
        );
    
    return (
        <>
        Logging out...
        </>
    )
}

export { Logout }
