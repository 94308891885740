import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ARTICLES_QUERY } from './Articles';

const StyledDiv = styled.div`
    max-width: 800px;
    margin: 20px auto;
`;

const StyledSearchTerm = styled.div`
    font-weight: bold;
`;

const StyledArticlesDiv = styled.div`
    margin: 10px 0;
`;
const StyledSpanContainerDiv = styled.div`
    margin: 10px 0;
`;
const StyledNextSpan = styled.span``;
const StyledPreviousSpan = styled.span``;

const Search = (props: any) => {
    const [pages, setPages] = useState([[]]);
    const [count, setCount] = useState(0);
    const [currentPageNumber, setcurrentPageNumber] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrevious, setHasPrevious] = useState(false);
    const searchTerm = (props.location.state && props.location.state.q) ? props.location.state.q : undefined;
    const options: {variables: any} = {
        variables: {
            // TODO: should be able to limit how many graphql must sent and use built in pagination
            // first: 10,
            isPublished: true,
            searchTerm,
            sort: "CREATED_AT_DESC",
        }
    }

    const {data, error, loading } = useQuery(ARTICLES_QUERY, options);

    useEffect(() => {
        document.title = "Search | Science Rag";
    }, []);

    useEffect(
        () => {
            if (data) {
                const articlesData = data.allArticles.edges
                .map(
                    ({ node }: any) =>
                        <div key={node.id} >
                            <Link to={`/articles/${node.id}`} >{node.title}</Link> {node.createdAt.split('T')[0]}
                        </div>
                );

                // place 10 in one array
                // then make new array
                const target: any = [];
                let counter = 0;
                let pageNumber = -1;
                articlesData.forEach((articleDiv: any) => {
                    if (counter % 10 === 0) {
                        target.push([]);
                        pageNumber++;
                    }
                    target[pageNumber].push(articleDiv);

                    counter++;
                });
                setCount(counter);
                setPages(target);
                setHasNext(currentPageNumber < target.length - 1);
                setHasPrevious(false);
            }
        },
        [data, currentPageNumber]
    );

    if (loading) {
        return null;
    }

    if (error) {
        console.log(JSON.stringify(error, null, 2));
        return <>There was an error.</>;
    }

    const handleNextPage = () => {
        const nextPageNumber = currentPageNumber + 1;
        setcurrentPageNumber(nextPageNumber);
        setHasNext(nextPageNumber < pages.length - 1);
        setHasPrevious(true);
    }

    const handlePreviousPage = () => {
        const previousPageNumber = currentPageNumber - 1;
        setcurrentPageNumber(previousPageNumber);
        setHasNext(true);
        setHasPrevious(previousPageNumber > 0);
    }

    return (
        <StyledDiv>
            <StyledSearchTerm>
                Search text: {searchTerm}
            </StyledSearchTerm>
            <div>
                Results: {count}
            </div>
            <StyledArticlesDiv>
                {pages[currentPageNumber]}
            </StyledArticlesDiv>
            <StyledSpanContainerDiv>
            {
                hasPrevious &&
                <StyledPreviousSpan onClick={() => handlePreviousPage()}>Previous</StyledPreviousSpan>
            } {
                hasNext &&
                <StyledNextSpan onClick={() => handleNextPage()}>Next</StyledNextSpan>
            }</StyledSpanContainerDiv>
        </StyledDiv>
    )
}

export { Search }
