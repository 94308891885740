import React from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { deleteNodeConfirmed } from '../helpers';
import { DELETE_USER_MUTATION } from './Users';

interface UserContentProps {
    user: any
}
const UserContent: any = (props: UserContentProps) => {
    const { user } = props;

    const [deleteUser] = useMutation(DELETE_USER_MUTATION);
    const confirmAndDelete = async (e: any, node: any) => {
        window.confirm(`Delete user ${node.email}?`) 
          && await deleteNodeConfirmed(deleteUser, e, node, '/users');
      }

    return (
        <div>
            <div>
                <h1>{user.email}</h1>
                &nbsp;
                <Link to={'/users/' + user.id + '/edit'} >Edit</Link>
                &nbsp;
                <Link to='users' onClick={(e) => confirmAndDelete(e, user)}>Delete</Link>
            </div>
        </div>
    )
};

export { UserContent }
