import React, { useContext, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    bodyContainerStyle,
    bodyEditorStyle,
    bodyTextAreaStyle,
    CenteredWrapperDiv,
    StyledForm } from '../styles';
import { WysiwygEditor } from './WysiwygEditor';
import { UserContext } from '../UserContext';
import { FileUpload } from './FileUpload';
import { uploadImage } from '../helpers';
import { ALL_TAGS_QUERY } from './TagsSelect';

const CREATE_ARTICLE_MUTATION = gql `
mutation CreateArticle($inputData: CreateArticleInput!) {
  createArticle(inputData: $inputData) {
    id
    title
    subtitle
    body
  }
}
`;

export const CreateArticle: any = (props: any) => {
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [body, setBody] = useState("");
    const [isPublished, setIsPublished] = useState(false);
    const [files, setFiles] = useState<FileList | null>();
    // will treat tags like state b/c updating state based on selectedValues is failing
    const [tags, setTags] = useState<any[]>([]);
    const userContext = useContext(UserContext);
    const { userId } = userContext;
    const { data, loading, error } = useQuery(ALL_TAGS_QUERY);

    const options: any = {
        variables: {
            inputData: {
                title,
                subtitle,
                body,
                userId,
                isPublished,
            },
        },
    }
    const [createArticle] = useMutation(CREATE_ARTICLE_MUTATION);
    
    const handleChange = (e: any) => {
        const options = e.target.options;
        
        const selectedValues: number[] = [];

        for (const option of options) 
            if (option.selected)
                selectedValues.push(parseInt(option.value));

        setTags(selectedValues);
    }
    
    if (loading)
        return null;

    if (error)
        console.log('error', error);

    const tagOptions = data?.allTags?.edges?.map(({ node }: any) => 
        <option value={parseInt(node.id)}  key={parseInt(node.id)}>
            {node.name.split('.')[1].toLowerCase()}
        </option>
    );

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        options.variables.inputData.tags = [...tags];
        await createArticle(options)
            .then(async (result) => {
                const articleId = result.data.createArticle.id;
                await uploadImage(files, 'POST', articleId)
                    .then(
                        () => document.location.pathname =
                            `/${document.location.pathname.split('/')[1]}/${articleId}`    
                    )
                    .catch((error) => console.log(JSON.stringify(error, null, 2))
                    );
            })
            .catch(
                (error) => console.log(JSON.stringify(error, null, 2))
            );
    };

    const handleCheckboxClick = (e: any) => {
        setIsPublished(!isPublished || false);
    }

    return (
        <CenteredWrapperDiv>
            <StyledForm onSubmit={(e: any) => handleSubmit(e)}>
                <label>
                Title:
                <textarea value={title} onChange={(e) => setTitle(e.target.value)} />
                </label>

                <label>
                Subtitle:
                <textarea value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
                </label>

                <label>
                    Body:
                    <div style={bodyContainerStyle} >
                        <div style={bodyEditorStyle}>
                            {<WysiwygEditor body={body} setBody={setBody} />}
                        </div>
                            <textarea
                                readOnly
                                style={bodyTextAreaStyle} 
                                value={body || ''}
                                onChange={(e) => setBody(e.target.value)}
                            />
                    </div>

                </label>

                <label>
                    Tags:
                    <div style={bodyContainerStyle} >
                    <select name="tags" id="tags" multiple={true} onChange={(e: any) => handleChange(e)} >
                        {tagOptions}
                    </select>
                    </div>

                </label>

                <label>
                    <div style={bodyContainerStyle} >
                        Publish now
                        <input
                            type='checkbox'
                            onChange={(e: any) => handleCheckboxClick(e)}
                            checked={!!isPublished || false}
                        />
                    </div>
                </label>

                <FileUpload files={files} setFiles={setFiles} />

                <input type="submit" value="Submit" />
            </StyledForm>
        </CenteredWrapperDiv>
    )
};
