import { gql, useMutation, useQuery } from '@apollo/client';
import React, {useState} from 'react';

interface DeleteShortlinkInput {
    code: string;
}

// eslint-disable-next-line
interface CreateShortlinkInput extends DeleteShortlinkInput {
    endpoint: string;
}

const SHORTLINK_QUERY = gql`
    query GetShortlinks {
    shortlinks {
            edges {
                node {
                    code
                    endpoint
                }
            }
        }
    }
`;

const DELETE_SHORTLINK_MUTATION = gql`
    mutation DeleteShortlinkMutation($inputData: DeleteShortlinkInput!) {
    deleteShortlink(inputData: $inputData) {
            ok
        }
    }
`;
const CREATE_SHORTLINK_MUTATION = gql`
    mutation CreateShortlinkMutation($inputData: CreateShortlinkInput!) {
    createShortlink(inputData: $inputData) {
            code
            endpoint
        }
    }
`;

const table = (rows: Array<React.ReactElement>) => (
    <table>
        <thead>
            <tr>
                <th>Code</th>
                <th>Endpoint</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </table>
);

const Shortlinks = () => {
    const [code, setCode] = useState<string>("");
    const [endpoint, setEndpoint] = useState<string>("");
    const [formAlert, setFormAlert] = useState<string>("");
    const {data, error, loading: isLoading, refetch} = useQuery(SHORTLINK_QUERY);
    const [deleteShortlink] = useMutation(DELETE_SHORTLINK_MUTATION);
    const [createShortlink] = useMutation(CREATE_SHORTLINK_MUTATION);

    const handleDelete = async (event: any) => {
        event.preventDefault();
        const codeToDelete = event.target?.value;

        await deleteShortlink(
            {variables: {inputData: {code: codeToDelete}}}
        ).then((result) => {
            setFormAlert(`Code ${codeToDelete} deleted!`);
            refetch();
        }).catch((error) => {
            const formattedError = JSON.stringify(error, null, 2);
            setFormAlert(`Deleting code ${codeToDelete} failed! ${formattedError}`);
        });
    }

    const handleCreate = async (event: any) => {
        event.preventDefault();
        console.log("event", event, "code", code, "endpoint", endpoint);

        await createShortlink({
            variables: {
                inputData: {
                    code: code,
                    endpoint: endpoint,
                },
            }
        }).then((result) => {
            setFormAlert(`Code ${code} created!`);
            refetch();
        }
        ).catch((error) => {
            const formattedError = JSON.stringify(error, null, 2);
            console.log(formattedError);
            setFormAlert(`Creating code ${code} failed! ${formattedError}`);
        });
    }

    const formElement = () => (
        <>
            <div>{formAlert}</div>
            <form onSubmit={handleCreate}>
                <label>Code
                    <input
                        type="text"
                        value={code || ""}
                        name="code"
                        onChange={(e) => setCode(e.target.value)}
                    />
                </label>
                <label>Endpoint
                    <input
                        type="text"
                        value={endpoint || ""} 
                        name="endpoint"
                        onChange={(e) => setEndpoint(e.target.value)} 
                    />
                </label>
                <input type="submit" />
            </form>
        </>
    )

    if (error) {
        // TODO: log errors
        return table([]);
    }

    if (isLoading) {
        return null;
    }

    if (data) {
        const tableRows = data.shortlinks.edges.map(
            (
                {node: {code, endpoint}}: 
                {node: {code: string, endpoint: string}}
            ) =>
                <tr key={code}>
                    <td>{code}</td>
                    <td>{endpoint}</td>
                    <td>
                        {/* TODO */}
                        <button>Update</button>
                        <button value={code} onClick={handleDelete}>
                            Delete
                        </button>
                    </td>
                </tr>
        );
        return(
            <>
                {formElement()}
                {table(tableRows)}
            </>
        )
    }

    return (
        <>
            {formElement()}
            {table([])}
        </>
    );
}

export { Shortlinks }
