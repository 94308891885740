import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Input, Icon, Button } from 'semantic-ui-react';
import { UserContext } from '../UserContext';
import { LOGO_URL } from '../constants';

interface HeaderProps {
    className: string;
}

const key = 0;

const Header: any = (props: HeaderProps) => {
    const { className } = props;
    const  [showMenuOptions, setShowMenuOptions] = useState(false);
    const  [isSearching, setIsSearching] = useState(false);
    const  [searchText, setSearchText] = useState('');
    const history = useHistory();

    const userContext = useContext(UserContext);
    const isAdmin = userContext.role === 'admin';
    const isEditor = userContext.role === 'editor';

    const handleEvent = (e: any, callback: any, arg: any) => {
        e.preventDefault();
        callback(arg);
        e.stopPropagation();
    }
    const handleClickMenu = (e: any) => {
        handleEvent(e, setShowMenuOptions, !showMenuOptions);
    }
    const handleClickInput = (e: any) => {
        switch (e.detail) {
            case 1:
                setShowMenuOptions(false);
                handleEvent(e, setIsSearching, true);
                break;
            case 2:
                handleSubmit(e);
                break;
        }
    }
    const handleBlur = (e: any) => {
        handleEvent(e, setIsSearching, false);
    }
    const handleSubmit = (e: any) => {
        if (!searchText || searchText === "") {
            return;
        }
        history.push('/search', {q: searchText});
        setSearchText('');
    }

    window.onclick = () => setShowMenuOptions(false);

    return (
        <StyledContainer className={className}>

            <StyledTitleIcon>
                <Link to='/'>
                    <img src={LOGO_URL} alt="ScienceRag Logo" width='100%' />
                </Link>
            </StyledTitleIcon>

            <StyledTitle exact to="/" className='title'>
                Science Rag
            </StyledTitle>


            <StyledSearchMenuDiv className='icons'>
                 <StyledForm onSubmit={(e: any) => handleSubmit(e)} >
                    <Input
                        name='search'
                        transparent
                        id='search'
                        type='text'
                        value={searchText || ''}
                        onClick={(e: any) => handleClickInput(e)}
                        onBlur={(e: any) => handleBlur(e)}
                        onChange={(e: any) => setSearchText(e.target.value)}
                        icon='search'
                        style={{
                            ...inputStyle,
                            backgroundColor: isSearching ? '#eeeeee' : '#ffffff',
                            borderBottom: '2px ' + (isSearching ? 'solid' : 'hidden') + ' black',
                            width: isSearching ? '100%' : '30px',
                        }}
                    />
                </StyledForm>
                {
                    !isSearching &&
                    <StyledIconButton
                        children={[
                            <StyledIcon
                                name='bars'
                                size="big"
                                key={key}
                            />
                        ]}
                        onClick={(e: any) => handleClickMenu(e)}
                    />
                }
            </StyledSearchMenuDiv>
            {showMenuOptions &&
                <StyledMenuOptionsDiv>
                    {
                        isAdmin && 
                        <>
                            <StyledMenuItem
                                className="dropdown-item"
                                exact to="/articles"
                            >Manage Articles</StyledMenuItem>
                            <StyledMenuItem
                                className="dropdown-item"
                                exact to="/shortlinks"
                            >Manage Shortlinks</StyledMenuItem>
                            <StyledMenuItem
                                className="dropdown-item"
                                exact to="/users"
                            >Manage Users</StyledMenuItem>
                        </>
                    }
                    {
                        isEditor && 
                        <>
                            <StyledMenuItem
                                className="dropdown-item"
                                exact to="/articles"
                            >Manage Articles</StyledMenuItem>
                        </>
                    }
                    {
                        !userContext.isLoggedIn && 
                        <>
                            <StyledMenuItem
                                className="dropdown-item"
                                exact to="/submissions"
                            >Submissions</StyledMenuItem>
                            <StyledMenuItem
                                className="dropdown-item"
                                exact to="/about"
                            >About Us</StyledMenuItem>
                            <StyledMenuItem
                                exact to="/login"
                            >Log In</StyledMenuItem>
                        </>
                    }
                    {
                        userContext.isLoggedIn &&
                        <>
                            {/* TODO add profile link to header, router
                            <StyledMenuItem
                                exact to="/profile"
                                >Profile</StyledMenuItem> */}
                            <StyledMenuItem
                                exact to="/logout"
                                >Log Out</StyledMenuItem>
                        </>
                    }
                </StyledMenuOptionsDiv>
            }
        </StyledContainer>
    )
}
const StyledContainer = styled.div`
    margin: 0;
`;

// #region styled nav link
const StyledNavLink = styled(NavLink)`
    display: block;
    color: black;
    text-decoration: none;
`;

const StyledMenuItem = styled(StyledNavLink)`
    right: 0;
    width: 200px;
    background-color: #eeeeee;
    border: 1px solid;
    padding: 10px;
    
`;
const StyledTitleIcon = styled.div`
    grid-column: 1;
    width: clamp(60px, 5vw, 100px);
    padding: 20px 0px;


`;
const StyledTitle = styled(StyledNavLink)`
    font-family: 'CynRegularBold';
    font-size: clamp(35px, 5vw, 50px);
    padding: 10px 0 0 0;
    grid-column: 2;
`;

// #endregion styled nav link

// #region search and menu
const StyledSearchMenuDiv = styled.div`
    justify-self: end;
    display: flex;
`;

// #region search
const StyledForm = styled(Form)`
    display: inline;
    margin: 0 10px 0 0;
    width: 100%;

    .ui.transparent.input input {
        padding: 0 !important;
    }
    
`;

const inputStyle = {
    fontSize: '25px',
}
// #endregion search

// #region menu and options style
const StyledIcon = styled(Icon)``;

const StyledIconButton = styled(Button)`
    grid-column: 3;
    background: #ffffff !important;
    color: #000000 !important;
    padding: 0 0 8px 0 !important;
    margin: 0 !important;

    :focus {
        outline: solid;
    }
`;

const headerHeight = `60px`;
const StyledMenuOptionsDiv = styled.div`
    position: absolute;
    top: ${headerHeight};
    right: 0;
    font-size: 20px;
`;
// #endregion menu options style
// #endregion search and menu

export const StyledHeader = styled(Header)`
    box-shadow: 0 3px 2px rgb(0 0 0 / 0.2);
    flex: 0;
    font-size: 30px;
    height: ${headerHeight};
    white-space: nowrap;

    display: grid;
    // figures represent space for the following:
    // icon, title, (search and menu)
    grid-template-columns: auto 6fr auto;
    grid-template-rows: ${headerHeight};
    align-items: center;
`;
