import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import { CreateUser } from './CreateUser';
import { deleteNodeConfirmed } from '../helpers';
import { UserContext } from '../UserContext';

const USERS_QUERY = gql `
query GetUsers {
  allUsers {
    edges {
      node {
        id
        email

      }
    }
  }
}
`;

export const DELETE_USER_MUTATION = gql `
mutation DeleteUser($inputData: DeleteUserInput!){
  deleteUser(inputData: $inputData) {
    ok
  }
}
`;

export const Users: any = (props: any) => {
  const [showNewUserNode,setShowNewUserNode] = useState(false);
  const { data, error, loading } = useQuery(USERS_QUERY);
  const [deleteUser] = useMutation(DELETE_USER_MUTATION);
  const userContext = useContext(UserContext);
  const { role } = userContext;
  const isAdmin = role === 'admin';

  const confirmAndDelete = async (e: any, node: any) => {
    window.confirm(`Delete user ${node.email}?`) 
      && await deleteNodeConfirmed(deleteUser, e, node, '/users');
  }

  useEffect(() => {
    document.title = "Users | Science Rag";
  }, []);
  if (loading)
      return null;
  if (error) {
    console.log(error, error.message);
    return <>Error</>;
  }

  const users = data.allUsers.edges
  .map(
    ({ node }: any) => {
      const userUrl = "/users/" + node.id;
      return (
        <div key={node.id}>
          <Link to={userUrl} >{node.email}</Link>
          {isAdmin && <>
            &nbsp;
            <Link to={userUrl + '/edit'} >Edit</Link>
            &nbsp;
            <Link to='users' onClick={(e) => confirmAndDelete(e, node)}>Delete</Link>
          </>}
        </div>
      )
    }
  );

  return (
      <div>
          <div>
              <Link to={'show new user nodes'} onClick={(e) => {
                e.preventDefault();
                setShowNewUserNode(!showNewUserNode);
              }} >
                New User
              </Link>
          </div>
          {
            showNewUserNode && 
            <div>
              <CreateUser />
            </div>
          }
          {
          (users.length > 0 && users)
          || "There are no users."
          }
      </div>
  )
};