import React from 'react';
// import { StyledForm } from '../styles';

const FileUpload = (props: any) => {
    const { setFiles } = props;
    return (
        // <StyledForm onSubmit={(e: any) => handleSubmit(e)}>

            <label>
                Image Upload
                <div>
                <input type="file" id="file" name="file" onChange={(event) => setFiles(event.currentTarget.files)} />
                </div>
            </label>
           // <input type="submit" value="Submit" />
        //</StyledForm>
    )
}

export { FileUpload }