import { gql, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

export interface Shortlink {
    codes: string[];
}

const SHORTLINK_QUERY = gql`
    query GetShortlinks($codes: [String]!) {
    shortlinks(codes: $codes) {
            edges {
                node {
                    endpoint
                }
            }
        }
    }
`;

const sources = (pathname: string): object => {
    return ({
        search: `?utm_source=scir.ag.${pathname.slice(1)}`,
    })
}

const ShortlinkPage = (props: any) => {
    const options: any = {
        variables: {
            codes: [props.match.params[0]],
        }
    }

    const history = useHistory();
    const location = useLocation();
    const { data, error, loading } = useQuery(SHORTLINK_QUERY, options);

    if (error) {
        history.push({
            pathname: "/",
            ...sources(location.pathname),
        });
    }

    if (loading) {
        return null;
    }

    if (data) {
        const { endpoint } = data.shortlinks.edges[0].node;
        history.push({
            pathname: endpoint,
            ...sources(location.pathname),
        });
    }

    return (
        <div></div>
    );
}

export { ShortlinkPage as Shortlink }
