import styled from "styled-components";

const StyledDiv = styled.div`
    max-width: 600px;
    margin: 20px auto;
`;

const pageContent = () => {
    return (
        <StyledDiv>
            <h1>About <i>Science Rag</i></h1>

            <p>Bernadette Davis founded <i>Science Rag</i> in 2021.</p>

            <p>The mission of <i>Science Rag</i> is to appreciate and reimagine the latest and the greatest of reported scientific achievements.</p>

            <h2>Masthead</h2>
            <p>Bernadette Davis - EDITOR IN CHIEF</p>

            <h3>Current and Previous Contributors</h3>
            <p>Alakananda Mookerjee</p>
            <p>Anna Denson</p>
            <p>Ben Miller</p>
            <p>Brian Marango</p>
            <p>Clancy Carrerra</p>
            <p>Elizabeth Dunlap</p>
            <p>Elizabeth Franklin</p>
            <p>Emma Colwell</p>
            <p>Fiona M. Jones</p>
            <p>Frances Davis</p>
            <p>Gale Robinson</p>
            <p>Jon Peahl</p>
            <p>Katie Taranto</p>
            <p>Madeleine Reichman</p>
            <p>Peter Lee</p>
            <p>Rémy Dambron</p>
            <p>Richard Gibson</p>
            <p>Stephen Michael DiPesa</p>
            <p>Ted Slotnik</p>
            <p>Thomas Walling</p>
        </StyledDiv>
    );
}
const About = () => {
    return pageContent();
}

export { About };
