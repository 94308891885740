import React, { useEffect } from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { deleteNodeConfirmed } from '../helpers';

export const ARTICLES_QUERY = gql`
query GetArticles($isPublished: Boolean $last: Int $first: Int $searchTerm: String $sort: [ArticleSortEnum]) {
  allArticles(isPublished: $isPublished last: $last first: $first searchTerm: $searchTerm sort: $sort) {
    edges {
      node {
        id
        createdAt
        isPublished
        title
      }
    }
  }
}
`;

export const DELETE_ARTICLE_MUTATION = gql`
mutation DeleteArticle($inputData: DeleteArticleInput!){
  deleteArticle(inputData: $inputData) {
    ok
  }
}
`;

export const Articles: any = () => {
  // TODO: add first option and paginate results
  const options = {
    variables: {
        sort: "CREATED_AT_DESC",
    },
}
  const { data, error, loading } = useQuery(ARTICLES_QUERY, options);
  const [deleteArticle] = useMutation(DELETE_ARTICLE_MUTATION);

  useEffect(() => {
    document.title = "Articles | Science Rag";
  }, []);

  const confirmAndDelete = async (e: any, node: any) => {
    window.confirm(`Delete article ${node.title}?`) 
      && await deleteNodeConfirmed(deleteArticle, e, node, '/articles');
  }

  if (loading)
      return null
  if (error) {
    console.log(JSON.stringify(error, null, 2));
    return <>Error</>;
  }

  const articles = data.allArticles.edges
  .map(
    ({ node }: any) => {
      const articleUrl = `/articles/${node.id}`;
      return (
        <div key={node.id}>
          <Link to={articleUrl} >{node.title}</Link>
          &nbsp;
          <Link to={`${articleUrl}/edit`} >Edit</Link>
          &nbsp;
          <Link to={`/articles`} onClick={(e) => confirmAndDelete(e, node)} >Delete</Link>
          <br />
          {node.isPublished ? 'PUBLISHED' : 'DRAFT'}
          &nbsp;
          CREATED: {node.createdAt}
        </div>
      )
    }
  );

  return (
      <div>
          <div>
              <Link to={"/articles/create"} >New Article</Link>
          </div>
          {
          (articles.length > 0 && articles)
          || "There are no articles."
          }
      </div>
  )
};