import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { deleteNodeConfirmed, sanitizedBody } from '../helpers';
import { UserContext } from '../UserContext';
import { DELETE_ARTICLE_MUTATION } from './Articles';
import { ImageContent } from './ImageContent';
import styled from 'styled-components';
import { ARTICLE_CONTENT_DATA_TEST_ID } from '../constants';

const StyledDiv = styled.div`
    margin: 0 0 20px 0;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface ArticleContentProps {
    article: any,
    shareButtons: () => JSX.Element,
}
const ArticleContent: any = (props: ArticleContentProps) => {
    const { article, shareButtons } = props;
    const [deleteArticle] = useMutation(DELETE_ARTICLE_MUTATION);
    const userContext = useContext(UserContext);
    const { role } = userContext;
    const isAdmin = role === 'admin';
    const isEditor = role === 'editor';
    
    const safeBody = sanitizedBody(article.body);

    const confirmAndDelete = async (e: any, article: any) => {
        window.confirm(`Delete article ${article.title}?`) 
          && await deleteNodeConfirmed(deleteArticle, e, article, '/articles');
      }

    return (
        <StyledDiv data-testid={ARTICLE_CONTENT_DATA_TEST_ID} >
            <div>
                <h1>{article.title}</h1>
                {(isAdmin || isEditor) && <>
                    {article.isPublished ? 'PUBLISHED' : 'DRAFT'}
                    &nbsp;
                    <Link to={'/articles/' + article.id + '/edit'} >Edit</Link>
                    &nbsp;
                    <Link to='/articles' onClick={(e) => confirmAndDelete(e, article)} >Delete</Link>
                </>}
            </div>
            <StyledDiv><p>{article.subtitle}</p></StyledDiv>
            <FlexDiv><p>{article.date}</p>{shareButtons()}</FlexDiv>
            {article.imagePath && <StyledDiv>
                <ImageContent src={article.imagePath} />
            </StyledDiv>}
            <div id='body' dangerouslySetInnerHTML={{__html:safeBody}} />
        </StyledDiv>
    )
};

export { ArticleContent }