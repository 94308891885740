import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

// import { getImages } from '../helpers';
import { HeadlinesOnly, MainContainerDiv } from './HeadlineContainers';

//#region TopicPage
const TopicPageHeader = styled.div`
    font-family: 'Cabin';
    font-size: 30px;
    font-weight: bold;
    // border-top: 1px solid #aaaaaa;
`;
//#endregion TopicPage

const TAG_QUERY = gql`
    query GetArticlesByTag($id: ID, $name: String) {
        tag(id: $id, name: $name) {
            name
            articles {
                edges {
                    node {
                        id
                        title
                        isPublished
                    }
                }
            }
        }
    }
`;

// TODO: make props for page
type TopicPageProps = any;
// interface TopicPageProps {

// }

export const setTagName = (name: string) => {
    const tagNameCapsWords: string[] = name.split('.')[1].split('_');
    const tagNameWords = tagNameCapsWords.map(
        (word: string) => word[0] + word.slice(1, word.length).toLowerCase()
    );

    return tagNameWords.join(' ');
}

const TopicPage = (props: TopicPageProps) => {
    const [ articlesData, setArticlesData] = useState<Array<any>>([]);
    const [ isLoaded, setIsLoaded] = useState<boolean>(false);
    const topicSlug = props.match.params['slug'];

    useEffect(() => {
        const topicWords = topicSlug.split("-");
        const topic = topicWords.map((w: string) => {
            return w[0].toUpperCase() + w.substring(1).toLowerCase()
        }).join(" ")
        document.title = `${topic} | Science Rag`;
      }, [topicSlug]);
    // sets data for getting correct tag
    const options: any = {
        variables: {
            name: topicSlug.toUpperCase().replaceAll('-', '_'),
        }
    }

    const { data, error, loading } = useQuery(TAG_QUERY, options);

    useEffect(() => {
        if (!data)
            return;

        const ids: string[] = [];
        const articles = data.tag.articles.edges.map(
            ({ node }: {node: {'id': string, [key: string]: any}}) => {
                ids.push(node.id);
                return node;
            }
        ).filter(
            // TODO: filter in backend
            (e: any) => e.isPublished
        );

        setArticlesData(articles);

        if (articles.length > 0) {
            setIsLoaded(true);
            // getImages(ids, articles, setArticlesData, setIsLoaded);
        }

    }, [data]);

    if (loading || !isLoaded)
        return null;
    if (error) {
        console.log(JSON.stringify(error, null, 2));
        return <>Error</>;
    }
    
    if (!articlesData || articlesData.length === 0)
        return (<>There are no articles with this tag.</>)

    return (
        <MainContainerDiv>
                <TopicPageHeader>
                    {setTagName(data.tag.name) || 'No topic'}
                </TopicPageHeader>
                <HeadlinesOnly articles={articlesData} />
        </MainContainerDiv>
    )
    // TODO: revert this to previous version when there are photos
}

export { TopicPage }
