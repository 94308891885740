import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { crossOrigin } from '../helpers';

interface HeadlinesProps {
    topic?: string,
    articles: {
        id: string,
        title: string,
        imagePath: string,
    }[],
}

/** styles */
//#region Main Container
const MainContainerDiv = styled.div`
    font-family: Cabin;
    font-size: 20px;
    line-height: normal;
    margin: 20px 0;
    max-width: 900px;

    a {
        color: black;
    }
    a:hover {
        color: #777777;
    }
    @media (min-width: 950px) {
        margin: 20px auto;
    }
`;
//#endregion Main Container
//#region TwoHeadline
const TwoHeadlineContainerDiv = styled.div`
    margin: 20px 0 0 0;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
`;
//#endregion TwoHeadline
//#region TwoImageHeadline
const TwoImageHeadlineContainerDiv = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // note: at about 400px, a headline cannot resize any smaller
    // at that point, smaller resizing results in image misalignment
`;
const TwoImageHeadlineInnerDiv = styled.div`
    margin: 20px 0 0 0;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 3fr 1fr;    
`;
const TwoImageHeadlineLeftImageDiv = styled.div`
    width: 100%;
    height: 20vw;

    @media (max-width: 650px) {
        height: 40vw;
    }
    @media (max-width: 420px) {
        width: 60vw;
        height: 35vw;
    }

`;

const TwoImageHeadlineRightHeadlineDiv = styled.div``;
//#endregion TwoImageHeadline
//#region ThreeImageHeadline
const ThreeImageHeadlineContainerDiv = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;

    @media (max-width: 650px) {
        grid-template-columns: 1fr;
    }
`;
const OneThirdInnerDiv = styled.div`
    @media (max-width: 650px) {
        display: block;
        float: left;
    }
`;
const OneThirdImage = styled.img`
    width:100%;
    height: 100%;
`;
const OneThirdImageDiv = styled.div`
    width: 30vw;
    height: 18vw;

    @media (max-width: 650px) {
        width: 60vw;
        height: 35vw;
        display: block;
        float: left;
        margin-right: 10px;
    }
    @media (min-width: 900px) {
        width: 270px;
        height: 160px;
    }
`;
const OneThirdHeadline = styled.span``;
//#endregion ThreeImageHeadline
//#region FrontPage
const FrontPageContainer = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 2fr 1fr;

    @media (max-width: 650px) {
        grid-template-columns: 1fr;
    }
`;
const FrontPageImage = styled.img`
width: 100%;
height: 100%;
`;
//#region LeftContainer
const FrontPageLeftContainer = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;

`;
const FrontPageHeadlinerContainer = styled.div`
    display: grid;
    grid-template-rows: 35vw auto;
    grid-gap: 10px;

    @media (max-width: 650px) {
        grid-template-rows: 50vw auto;
    }
    @media (min-width: 900px) {
        grid-template-rows: 300px auto;
    }
`;
const FrontPageHeadlinerImageContainer = styled.div``;
const FrontPageHeadlinerHeadlineContainer = styled.div``;
const FrontPageDoubleHeadlineContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 325px) {
        grid-template-columns: 1fr;
    }
`;
const FrontPageDoubleHeadlineSingleHeadlineContainer = styled.div`
    display: grid;
    grid-template-rows: 15vw auto;
    grid-gap: 10px;

    @media (max-width: 650px) {
        grid-template-rows: 30vw auto;
    }

    @media (max-width: 325px) {
        grid-template-rows: auto;
        grid-template-columns: 2fr 1fr;
    }

    @media (min-width: 900px) {        
        grid-template-rows: 150px auto;
    }
`;
const FrontPageDoubleHeadlineSingleHeadlineImageContainer = styled.div`
    @media (max-width: 325px) {
        width: 100%;
        height: 30vw;
    }
`;
const FrontPageDoubleHeadlineSingleHeadlineHeadlineContainer = styled.div``;
//#endregion LeftContainer
//#region RightContainer
const FrontPageRightContainer = styled.div``;
const MostReadDiv = styled.div`
    font-family: 'Cabin';
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0;
`;
const FrontPageSideLineContainer = styled.div`
    display: grid;
    grid-template-columns: 20px auto;
    grid-gap: 10px;
    margin: 20px 0;
`;
//#endregion RightContainer
//#endregion FrontPage


const FrontPageGrid = (props: HeadlinesProps) => {
    let order = 0;
    let popularOrder = 0;
    const articles = props.articles.map(({ id, title, imagePath }) => {
        const currentOrder = order;
        order++;
        switch (currentOrder){
            case 0:
                return (
                    <FrontPageHeadlinerContainer>
                        <FrontPageHeadlinerImageContainer>
                            <Link to={`/articles/${id}`}>
                                <FrontPageImage src={imagePath} crossOrigin={crossOrigin} />
                            </Link>
                        </FrontPageHeadlinerImageContainer>

                        <FrontPageHeadlinerHeadlineContainer>
                            <Link to={`/articles/${id}`} dangerouslySetInnerHTML={{__html: title}} />
                        </FrontPageHeadlinerHeadlineContainer>
                    </FrontPageHeadlinerContainer>
                );
            case 1:
            case 2:
            case 3:
            case 4:
                return (
                    <FrontPageDoubleHeadlineSingleHeadlineContainer>
                         <FrontPageDoubleHeadlineSingleHeadlineImageContainer>
                            <Link to={`/articles/${id}`} >
                                <FrontPageImage src={imagePath} crossOrigin={crossOrigin} />
                            </Link>
                         </FrontPageDoubleHeadlineSingleHeadlineImageContainer>

                         <FrontPageDoubleHeadlineSingleHeadlineHeadlineContainer>
                            <Link to={`/articles/${id}`} dangerouslySetInnerHTML={{__html: title}} />
                         </FrontPageDoubleHeadlineSingleHeadlineHeadlineContainer>

                     </FrontPageDoubleHeadlineSingleHeadlineContainer>
                );
            case 5:
            case 6:
            case 7:
            case 8:
                popularOrder++;
                return (
                    <FrontPageSideLineContainer>
                        {popularOrder}
                        <Link to={`/articles/${id}`} dangerouslySetInnerHTML={{__html: title }} />
                    </FrontPageSideLineContainer>
                )
        }
        
        return (
            <>
            </>
        )
    });

    const sidelineIndexes = [5, 6, 7, 8]
    const sidelines = articles.filter(
        (_, i) =>  sidelineIndexes.includes(i)
    );

    return (
        <MainContainerDiv>
            <FrontPageContainer>
                <FrontPageLeftContainer>
                    {articles[0]}
                    <FrontPageDoubleHeadlineContainer>
                    {articles[1]}
                    {articles[2]}
                    </FrontPageDoubleHeadlineContainer>
                    <FrontPageDoubleHeadlineContainer>
                        {articles[3]}
                        {articles[4]}     
                    </FrontPageDoubleHeadlineContainer>
                </FrontPageLeftContainer>

                <FrontPageRightContainer>
                    <MostReadDiv>
                        Most Read
                    </MostReadDiv>
                    {sidelines}
                </FrontPageRightContainer>
            </FrontPageContainer>
        </MainContainerDiv>
    );
}

const HeadlinesOnly = (props: HeadlinesProps) => {
    /*
    concept:
        headline only 
        two of these juxtaposed
    */
    const articles = props.articles.map(({ id, title }) => 
        <div key={id}>
            <Link to={`/articles/${id}`} dangerouslySetInnerHTML={{__html: title}} />
        </div>
    )

    const pairArticles = () => {
        const articlePairs: Array<any> = [];
        let articlePairIndex = 0;
        let articleindex = 0;
    
        for (let article of articles) {
            if ((articleindex % 2) === 0) {
                articlePairs.push([article]);
            } else {
                articlePairs[articlePairIndex].push(article);
                articlePairIndex++;
            }
            articleindex++;
        }

        // conditionally adds empty div
        // this is so last article won't take up entire width
        // instead of just half
        if ((articles.length % 2) === 1)
            articlePairs[articlePairIndex].push(<div></div>);

        return articlePairs;
    }
    let keyNum = articles.length;
    const constructTwoHeadlines = (articles: Array<any>) => (
        <TwoHeadlineContainerDiv key={keyNum++} >
            {articles[0]}
            {articles[1]}
        </TwoHeadlineContainerDiv>
    )

    const constructHeadlines = () => {
        const headlineDivs: Array<any> = [];
        const articlePairs = pairArticles();

        for (const articlePair of articlePairs) {
            const headlinePair = constructTwoHeadlines(articlePair);
            headlineDivs.push(headlinePair);
        }

        return headlineDivs;
    }

    return (
        <MainContainerDiv>
            {constructHeadlines()}
        </MainContainerDiv>
    )
}

const ThreeImageHeadline = (props: HeadlinesProps) => {
    /*
    concept:
        three columns of image top, headline bottom
        small screen:
        one column of image top, headline bottom
    */ 
    const articles = props.articles.map(({ id, imagePath, title }) =>
        <OneThirdInnerDiv key={id} >
            <Link to={`/articles/${id}`} >
                <OneThirdImageDiv>
                    <OneThirdImage src={imagePath} crossOrigin={crossOrigin} />
                </OneThirdImageDiv>
            </Link>
            <OneThirdHeadline>
                <Link to={`/articles/${id}`} dangerouslySetInnerHTML={{__html: title}}/>
            </OneThirdHeadline>
        </OneThirdInnerDiv>
    )

    return (
        <MainContainerDiv>
            <ThreeImageHeadlineContainerDiv>
            {articles.filter((e, i) => i < 3)}
            </ThreeImageHeadlineContainerDiv>
        </MainContainerDiv>
    )
}

const TwoImageHeadlineInline = (props: HeadlinesProps) => {
    /*
    concept:
        two columns of (image left, headline right)
        small screen:
        one column of (image left, headline right)
    */ 
    const articles = props.articles.map(({ id, imagePath, title }) => 
        <>
                <TwoImageHeadlineInnerDiv>
                    <Link to={`/articles/${id}`} >
                        <TwoImageHeadlineLeftImageDiv>
                            <FrontPageImage src={imagePath} crossOrigin={crossOrigin} />
                        </TwoImageHeadlineLeftImageDiv>
                    </Link>
                    <TwoImageHeadlineRightHeadlineDiv style={{fontFamily: 'Cabin'}} >
                        <Link to={`/articles/${id}`} dangerouslySetInnerHTML={{__html: title}} />
                    </TwoImageHeadlineRightHeadlineDiv>
                </TwoImageHeadlineInnerDiv>
        </>
    )

    return (
        <MainContainerDiv>
            <TwoImageHeadlineContainerDiv>
            {articles[0]}
            {articles[1]}
            </TwoImageHeadlineContainerDiv>
        </MainContainerDiv>
    )
}

export {
    FrontPageGrid,
    HeadlinesOnly,
    ThreeImageHeadline,
    TwoImageHeadlineInline,
    FrontPageImage,
    MainContainerDiv,
};
