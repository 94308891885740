import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { UserContent } from './UserContent';
import { useParams } from 'react-router-dom';

export const USER_QUERY = gql `
query GetUser($id: ID!) {
  user(id: $id) {
        id
        email
        role
    }
}
`;

export const User: any = () => {
    const  params: any = useParams();
    const [ userId, setUserId ] = useState(undefined);

    const options = {
        variables: {
            id: userId,
        },
    }
    const { data, error, loading } = useQuery(USER_QUERY, options);

    useEffect(() => {
        document.title = "User | Science Rag";
    }, []);

    useEffect(() => {
        const userId = params['userId'];
        if (userId)
            setUserId(userId);
    }, [params]);

    if (loading)
        return null;
    if (error) {
        document.title = "User Not Found | Science Rag";
        console.log(error, error.message);
        return <>Error</>;
    }

    const { user } = data;

    if (!data.user)
        return <>User not found.</>

    return (
        <>
            <UserContent user={user} />
        </>
    )
};
