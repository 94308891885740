import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import { useHistory } from 'react-router-dom';
import { ArticleContent } from './ArticleContent';
// import { getImages } from '../helpers';
import styled from 'styled-components';
import { Tags } from './Tags';
import { UserContext } from '../UserContext';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

const StyledArticle = styled.div`
    max-width: 600px;
    margin: 20px auto;
`;

export const ARTICLE_QUERY = gql`
query GetArticle($id: ID!) {
  article(id: $id) {
        id
        isPublished
        body
        subtitle
        title
        createdAt
        tags {
            id
            name
        }
    }
}
`;

export const Article: any = (props: any) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [articles, setArticles] = useState([]);
    const [tags, setTags] = useState([]);
    const history = useHistory();

    const userContext = useContext(UserContext);
    const { role } = userContext;
    const isAdmin = role === 'admin';
    const isEditor = role === 'editor';

    const {articleId} = props.match.params;
    const articleIdIsNaN = isNaN(parseInt(articleId));

    const options = {
        variables: {
            id: articleIdIsNaN ? undefined : articleId,
        },
    }
    const { data, error, loading } = useQuery(ARTICLE_QUERY, options);

    useEffect(() => {
        if (!data)
            return;

        const articleDate = new Date(data.article.createdAt);
        const date = articleDate.toLocaleString();

        const ids: string[] = [];

        const articles: any = [data.article].map(
            (article) => {
                setTags(
                    article.tags.map(
                        (e: any) => ({
                            id: e.id,
                            name: e.name.split('.')[1].toLowerCase().replaceAll('_', ' '),
                        })
                    )
                );

                ids.push(article.id);
                return {...article, date: date};
            }
        );

        setArticles(articles);

        // todo: make fetch cancelable
        // cancel asynchronous tasks in a useEffect cleanup function

        // actually, replace fetch w/ graphql b/c promise is now receiving a url and not a whole image
        if (articles.length > 0) {     
            const wordLimit = 10;
            const titleWords = articles[0].title.split(" ");
            const ellipsis = titleWords.length > wordLimit ? "..." : ""; 
            document.title = `${titleWords.slice(0,wordLimit).join(" ")}${ellipsis} | Science Rag`;
            setIsLoaded(true);       
            // getImages(ids, articles, setArticles, setIsLoaded);
        }
    },[data]);
        
    if (loading || !isLoaded)
        return null;
    if (error) {
        document.title = "Article Not Found | Science Rag";
        console.log(error);
        console.log(JSON.stringify(error, null, 2));
        return <>Error</>;
    }

    const article: any = articles[0];

    if (!article.isPublished && !isAdmin && !isEditor){
        history.push('/');
    }

    if (!article){
        if (isAdmin || isEditor) {
            history.push('/articles');
        }
        history.push('/');
        // TODO: probably not necessary
        history.go(0);
    }

    const shareButton = (href: string, iconName: SemanticICONS, iconColor?: SemanticCOLORS) => (
        <a href={href} target='_blank' rel='noreferrer'>
            <Icon name={iconName} color={iconColor} size='big' />
        </a>
    )

    const shareButtons = () => (
        <div>
            {shareButton(
                `http://twitter.com/share?text=Check out this interesting article from @ScienceRag! ${article.title}&url=https://sciencerag.com/articles/${article.id}`,
                'twitter',
            )}
            
            {shareButton(
                `https://www.linkedin.com/sharing/share-offsite/?url=https://sciencerag.com/articles/${article.id}`,
                'linkedin',
            )}

            {shareButton(
                `https://www.reddit.com/submit?url=https://sciencerag.com/articles/${article.id}&title=Science%20Rag%20Article:%20${article.title}`,
                'reddit',
                'red',
            )}

            {shareButton(
                `mailto:?&subject=Science%20Rag%20Article&body=Check out this article from Science Rag ${article.title} https://sciencerag.com/articles/${article.id}`,
                'mail outline',
                'grey',
            )}
            

        </div>
    )

    return (
        <StyledArticle>
            <ArticleContent article={article} shareButtons={shareButtons} />
            {tags.length > 0 && 'FILED UNDER:'}
            <Tags tags={tags} />
        </StyledArticle>
    )
};
