import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

// import { getImages } from '../helpers';
import { ARTICLES_QUERY } from './Articles';
import {
    // FrontPageGrid,
    HeadlinesOnly,
    // ThreeImageHeadline,
    // TwoImageHeadlineInline
} from './HeadlineContainers';
// import { TopicPage } from './TopicPage';

const HomeTitles = () => {
    const options = {
        variables: {
            sort: "CREATED_AT_DESC",
            isPublished: true,
            first: 12,
        },
    }
    const { data, error, loading } = useQuery(ARTICLES_QUERY, options);
    const [articles, setArticles] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        document.title = "Home | Science Rag";
      }, []);

    useEffect(() => {
        if (!data)
            return;

        const ids: string[] = [];
        const articles = data.allArticles.edges.map(
            ({ node }: {node: {'id': string, [key: string]: any}}) => {
                ids.push(node.id);
                return node;
            }
        )
        setArticles(articles);

        if (articles.length > 0) {  
            setIsLoaded(true);
            // getImages(ids, articles, setArticles, setIsLoaded);
        }

    }, [data]);

    if (loading || !isLoaded)
        return null
    if (error) {
        console.log(JSON.stringify(error, null, 2));
        return <>Error</>;
    }

    return (
        <>
            {/* 
            Prefer FrontPageGrid for when there are images
            And fill the rest with ThreeImageHeadline and TwoImageHeadlineInline
            Will eventually need space/layouts for ads
            <FrontPageGrid articles={articles} />
            <ThreeImageHeadline articles={articles} />
            <TwoImageHeadlineInline articles={articles} /> */}
            <HeadlinesOnly articles={articles} />
        </>
    )
}


export { HomeTitles };
