import React, { useState } from 'react';

import { ContentBlock, ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from 'styled-components';
import { editorBody, widthConstants } from '../styles';

const WysiwygEditor = (props: {body: string, setBody: any}) => {
  const { body, setBody } = props;

  const createContentState = (articleAttribute: string = '') => {
    let base: { contentBlocks: ContentBlock[], entityMap: any } = convertFromHTML(articleAttribute || '');
    const contentBlocks = base.contentBlocks;
    return ContentState.createFromBlockArray(
      contentBlocks
    );
  }
  const contentState = createContentState(body);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    contentState
  ));
  
  const onChange: (editorState: EditorState) => void = editorState => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const markup = draftToHtml(
      rawContentState
    );
    
    setBody(markup);
    setEditorState(editorState);
  };
  // let domEditor: any;
  // const setDomEditorRef: (ref: any) => any = (ref: any) => domEditor = ref;
  

  return (
    <StyledEditorDiv>
        <Editor
          editorStyle={editorStyle || undefined}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onChange}
        />
    </StyledEditorDiv>
  );
  
}

const StyledEditorDiv = styled.div`
  margin: auto;
  width: ${widthConstants.width};
  min-width: ${widthConstants.minWidth};
  max-width: ${widthConstants.maxWidth};
`;
const editorStyle = {
  height: editorBody.height,
}

export { WysiwygEditor }