import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const StyledForm = styled.form`
    margin: 20px;
`;

const LOGIN_MUTATION = gql`
    mutation LoginUser($inputData: LoginInput!) {
        logInUser(inputData: $inputData) {
            user {
                id
                email 
            }
            nextUrl
            accessToken
        }
    }
`;

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const options = {
        variables: {
            inputData: {
                email,
                password,
            },
        },
    }

    useEffect(() => {
        document.title = "Login | Science Rag";
    }, []);

    const [login] = useMutation(LOGIN_MUTATION, options);
    const history = useHistory();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        await login()
            .then(
                (res: any) => {
                    const { nextUrl, accessToken } = res.data.logInUser;
                    localStorage.setItem('token', accessToken)
                    
                    history.push(nextUrl);
                    history.go(0);
                }
            )
            .catch(
                (error) => console.log(JSON.stringify(error, null, 2))
            );
    };

    return (
        <StyledForm onSubmit={(e: any) => handleSubmit(e)} >
            Email address:
            <input type='text' value={email || ''} onChange={(e) => setEmail(e.target.value)} />
            Password:
            <input type='password' value={password || ''} onChange={(e) => setPassword(e.target.value)} />
            <input type='submit' value='Submit' />
        </StyledForm>
    )
}

export { Login }
