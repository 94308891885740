import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TAGS_DATA_TEST_ID } from '../constants';

const StyledButton = styled.button`
    margin: 10px 0 10px 10px;
    border-radius: 5px;
    border: none;
    color: #000000;
    background-color: #e9e9e9;
`;

interface TagProps {
    id: string,
    name: string
}
interface TagsProps {
    tags: TagProps[]
}

const Tags = (props: TagsProps) => {
    const { tags } = props;
    const history = useHistory();

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const slug = event.currentTarget.textContent?.replaceAll(' ', '-');
        history.push(`/topics/${slug}`, {tagId: parseInt(event.currentTarget.value)});
    }

    const tagButtons = tags.map(
        ({id, name}: {id: string, name: string}) => 
            <StyledButton
                key={parseInt(id)}
                onClick={(e) => onClick(e)}
                value={id}
                children={name}
            />
    )

    return (
        <div data-testid={TAGS_DATA_TEST_ID} >
            {tagButtons}
        </div>
    )
}

export {
    Tags
};
export type { TagProps };
