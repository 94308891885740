import React from 'react';
import ReactDOM from 'react-dom';
import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';

import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './fonts/CabinRegular-G0GO.ttf';
import './fonts/CabinBold-DEDD.ttf';
import './fonts/CabinItalic-qmG1.ttf';
import './fonts/CabinBoldItalic-AE3p.ttf';
import './fonts/Caretodance-JrDn.ttf';
import './fonts/CynRegularBold-KdRo.ttf';
import './fonts/EbGaramond08Italic-7W1B.ttf';
import './fonts/EbGaramond08Regular-2mWe.ttf';
import './fonts/EbGaramond12Italic-dZKx.ttf';
import './fonts/EbGaramond12Regular-weJw.ttf';
import './fonts/Indifference-jYe7.ttf';
import './fonts/OldDogNewTricksCaps-B0PV.ttf';
import 'semantic-ui-css/semantic.min.css';

export const HAS_PROJECT_PATH = process.env.REACT_APP_PROJECT_PATH !== undefined;
export const URL_DOMAIN = HAS_PROJECT_PATH ? 'https://science-rag-backend.herokuapp.com' : 'http://localhost';
export const PORT = process.env.PORT || 5000;
export const URL = `${URL_DOMAIN}${HAS_PROJECT_PATH ? '' : `:${PORT}`}`;


// Google Analytics 4
const GA_MEASUREMENT_ID = "G-4CNLBWL4MJ";

ReactGA.gtag("consent", "default", {
  "ad_storage": "denied",
  "analytics_storage": "denied",
});
ReactGA.initialize(GA_MEASUREMENT_ID);
// Google Analytics 4


const httpLink = createHttpLink({
  uri: `${URL}/graphql`,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  return {
    fetchOptions: {
      credentials: 'include',
    },
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
const createdElement = () => {
  const element = document.createElement('div');
  element.id = 'root';

  return element;
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
      <CookieConsent
        buttonText='Enable all cookies'
        buttonStyle={{backgroundColor: '#303030', color: '#ffffff', border: '1px white solid'}}
        declineButtonText='Strictly necessary cookies only'
        declineButtonStyle={{backgroundColor: '#303030', border: '1px white solid', color: '#cccccc' }}
        enableDeclineButton
        flipButtons={true}
        overlay={true}
        onAccept={() => {
          ReactGA.gtag("consent", "update", {
            // "ad_storage": "granted",
            "analytics_storage": "granted",
          });
        }}
        style={{backgroundColor: '#303030', height: '280px', left: 'auto', maxWidth: '400px', minWidth: '320px',  right: 0, wordWrap: 'normal'}}
      >
        This site uses cookies for usage tracking and enabling basic functionality. Tracking cookies enable third-party analytics services to collect data on users, the pages they visit, and record how much time they spend on the <i>Science Rag</i> website. This information may be used for marketing purposes. Further information concerning cookies is available in the <a href='/privacy-policy'>privacy policy</a>.
      </CookieConsent>
    </ApolloProvider>
  </React.StrictMode>,
  // new div is for testing
  document.getElementById('root') || createdElement()
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
