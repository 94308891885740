import React from 'react';

import { crossOrigin } from '../helpers';
interface ImageContentProps {
    src: any
}
const ImageContent: any = (props: ImageContentProps) => {
    const { src } = props;
    const { innerWidth: width } = window;
    
    return (
    <>
        {src && 
        <img
            src={src}
            alt='Placeholder.'
            width={width * .9 }
            height={width * (2 / 3) * .9 }
            crossOrigin={crossOrigin}
        />}
    </>
    )
};

export { ImageContent }