import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { USER_QUERY } from './User';
import { useParams } from 'react-router-dom';
import { UserContext } from '../UserContext';

const EDIT_USER_MUTATION = gql `
mutation EditUser($inputData: EditUserInput!) {
  editUser(inputData: $inputData) {
    id
    email
  }
}
`;

export const EditUser: any = () => {
    const  params: any = useParams();
    
    const [ userId, setUserId ] = useState(undefined);

    const { error, loading, data } = useQuery(USER_QUERY, { variables: { id: userId }});

    const [email, setEmail] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [oldPassword, setOldPassword] = useState<string>("");
    const [userRole, setUserRole] = useState<string>("");
    const userContext = useContext(UserContext);
    const { role } = userContext;
    const isAdmin = role === 'admin';
    
    const options = {
        variables: {
            inputData: {
                email,
                role: userRole,
                id: userId,
                oldPassword,
                password: newPassword,
            },
        },
    }
    const [editUser] = useMutation(EDIT_USER_MUTATION, options);

    useEffect(() => {
        if (!data)
            return;
        setEmail(data?.user?.email);
        setNewPassword(data?.user?.newpassword);
        setOldPassword(data?.user?.oldpassword);
        setUserRole(data?.user?.role || '');
    }, [data]);

    useEffect(() => {
        const userId = params['userId'];
        if (userId)
            setUserId(userId);
    }, [params]);

    if (loading)
        return null;
    if (error) {
        console.log(JSON.stringify(error, null, 2));
        return <>Error</>;
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        await editUser()
            .then(
                () => document.location.pathname =
                '/' + document.location.pathname.split('/')[1] +
                '/' + userId
            )
            .catch(
                (error) => console.log(error)
            );
    };

    return (
        <>
            <form onSubmit={(e: any) => handleSubmit(e)}>
                <label>
                    Email:
                    <input
                        type='email'
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly={!isAdmin}
                    />
                </label>

                <label>
                    Old Password:
                    <input type='password' value={oldPassword || ''} onChange={(e) => setOldPassword(e.target.value)} />
                </label>

                <label>
                    New Password:
                    <input type='password' value={newPassword || ''} onChange={(e) => setNewPassword(e.target.value)} />
                </label>
                <label>
                    Role:
                    <select
                        name="role"
                        id="role"
                        onChange={(e) => setUserRole(e.target.value)}
                        disabled={!isAdmin}
                        value={userRole || ''}
                    >
                        <option value="" > -- Select One -- </option>
                        <option value="admin" >admin</option>
                        <option value="editor" >editor</option>
                    </select>
                </label>

                <input type="submit" value="Submit" />
            </form>
        </>
    )
};
