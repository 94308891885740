import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ARTICLE_QUERY } from './Article';
import { WysiwygEditor } from "./WysiwygEditor";

import { sanitizedBody, uploadImage } from '../helpers';
import { bodyContainerStyle, bodyEditorStyle, bodyTextAreaStyle, StyledForm } from '../styles';
import { FileUpload } from './FileUpload';
import { TagsSelect } from './TagsSelect';
import { UserContext } from '../UserContext';

const EDIT_ARTICLE_MUTATION = gql `
mutation EditArticle($inputData: EditArticleInput!) {
  editArticle(inputData: $inputData) {
    id
    title
    subtitle
    body
  }
}
`;

export const EditArticle: any = (props: any) => {
    const { articleId } = props.match.params;
    const userContext = useContext(UserContext);
    const { userId } = userContext;
    
    const { error, loading, data } = useQuery(ARTICLE_QUERY, { variables: { id: articleId }});

    const [title, setTitle] = useState<string>("");
    const [subtitle, setSubtitle] = useState<string>("");
    const [body, setBody] = useState<string>("");
    const [isPublished, setIsPublished] = useState(false);
    const [files, setFiles] = useState<FileList | null>();
    const [tags, setTags] = useState<any>([]);
    
    const options: any = {
        variables: {
            inputData: {
                id: articleId,
                isPublished,
                title,
                subtitle,
                body,
                userId,
            },
        },
    }
    const [editArticle] = useMutation(EDIT_ARTICLE_MUTATION);

    useEffect(() => {
        const { article } = data || {};
        setTitle(article?.title);
        setSubtitle(article?.subtitle);
        setIsPublished(article?.isPublished);
        setBody(article?.body);
        setTags(article?.tags);        
    }, [data]);

    if (loading)
        return null
    if (error) {
        console.log(error, error && error.message);
        return <>Error</>;
    }
    
    
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        options.variables.inputData.tags = tags.map((e: any) => e.id);
        options.variables.inputData.isPublished = isPublished;
        const safeBody = sanitizedBody(body);
        setBody(safeBody);

        await editArticle(options)
            .catch(
                (error) => console.log(error)
            );

        await uploadImage(files, 'POST', articleId)
            .then(
                () => 
                document.location.pathname =
                    `/${document.location.pathname.split('/')[1]}/${articleId}`
            )
            .catch(e => console.log(e)
            );
    };

    const handleCheckboxClick = (e: any) => {
        setIsPublished(!isPublished || false);
    }

    return (
        <>
            <StyledForm onSubmit={(e: any) => handleSubmit(e)}>
                <label>
                Title:
                <textarea value={title || ''} onChange={(e) => setTitle(e.target.value)} />
                </label>

                <label>
                Subtitle:
                <textarea value={subtitle || ''} onChange={(e) => setSubtitle(e.target.value)} />
                </label>

                <label>
                Body:

                <div style={bodyContainerStyle} >
                    <div style={bodyEditorStyle}>
                        {body && <WysiwygEditor body={body} setBody={setBody} />}
                    </div>
                        <textarea
                            readOnly
                            style={bodyTextAreaStyle} 
                            value={body || ''}
                            onChange={(e) => setBody(e.target.value)}
                        />
                </div>
                </label>

                <TagsSelect setTags={setTags} tags={tags} />

                <label>
                    <div style={bodyContainerStyle} >
                        Set as published
                        <input
                            type='checkbox'
                            onChange={(e: any) => handleCheckboxClick(e)}
                            checked={!!isPublished || false}
                        />
                    </div>
                </label>

                <FileUpload files={files} setFiles={setFiles} />
                <input type="submit" value="Submit" />
            </StyledForm>
        </>
    )
};
