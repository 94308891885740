import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const StyledFooterWrapper = styled.footer`
    background-color: #222222;
    box-shadow: 0 -3px rgb(0 0 0 / 0.4);
    color: white;

    a {
        color: #ffffff;
    }
`;

const StyledSmallContainer = styled.div`
margin: 0 10px 10px 10px;
display: flex;
flex-flow: row wrap;
justify-content: space-between;
// text-align: center;
`;
const StyledSmallLeft = styled.small`
justify-self: flex-start;
`;
const StyledSmallRight = styled.small`
justify-self: flex-end;
`;

const StyledFooterInner = styled.div`
    display: flex;
    flex-flow: row wrap;
    font-size: 20px;
    justify-content: space-between;
    padding:  20px 0;
    width: 100%;

    @media (max-width: 560px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const FooterInnerChildLeftTop = styled.div`
    margin-top: 10px;
`;

const FooterInnerChildMiddleBottom = styled.div`
    // min-width: 250px;
    // flex-basis: auto;
    margin: 0 0 10px 0;
`;

const FooterInnerChildRightMiddle = styled.div`
    font-size: 20px;
    line-height: 35px;
    margin: 0 10px 0 0;
    text-align: center;
    @media (max-width: 560px) {
        margin: 10px 0 0 0;
    }
`;

const FooterInnerTitle = styled.span`
    font-family: 'CynRegularBold';
    font-size: clamp(35px, 5vw, 50px);
    margin-left: 10px;
    line-height: clamp(35px, 5vw, 50px);
`;

const StyledIcon = styled(Icon)`
    color: white;
    line-height: 40px;
`;

const StyledLink = styled(Link)`
    // border: 1px solid white;
    // border-radius: 5px;
    margin: 10px;
    padding: 5px;
`;

const Footer: any = (props: any) => {
    const { className } = props;

    const unicodeDot = <>
        &nbsp;
        {/* {'\u2B24'}
        &nbsp; */}
    </>
    
    
    return (
        <StyledFooterWrapper >

            <StyledFooterInner className={className} >
                <FooterInnerChildLeftTop>
                    <FooterInnerTitle>
                        <Link to='/' >Science Rag</Link>
                    </FooterInnerTitle>
                </FooterInnerChildLeftTop>

                <FooterInnerChildMiddleBottom>
                    <StyledLink to={{pathname: 'mailto:info@sciencerag.com'}} target="_blank" >
                        <StyledIcon name='mail outline' />
                        email us
                    </StyledLink>
                    <br />
                    <br />

                    <StyledLink to={{pathname: 'https://twitter.com/sciencerag'}} target="_blank" >
                        <StyledIcon name='twitter' />
                        twitter
                    </StyledLink>
                </FooterInnerChildMiddleBottom>

                <FooterInnerChildRightMiddle>
                    <Link to='/topics/biology' >Biology</Link>
                    {unicodeDot}
                    <Link to='/topics/chemistry' >Chemistry</Link>
                    <br />
                    <Link to='/topics/earth-science'  >Earth Science</Link>
                    {unicodeDot}
                    <Link to='/topics/math' >Math</Link>
                    {unicodeDot}
                    <Link to='/topics/physics' >Physics</Link>
                    <br />
                    <Link to='/topics/space' >Space</Link>
                    {unicodeDot}
                    <Link to='/topics/technology' >Technology</Link>
                </FooterInnerChildRightMiddle>
            </StyledFooterInner>

            <StyledSmallContainer>
                <StyledSmallLeft>
                    <Link to='/privacy-policy' >Privacy Policy</Link>
                    &nbsp;|&nbsp;
                    <Link to='/terms-of-service' >Terms of Service</Link>
                </StyledSmallLeft>

                <StyledSmallRight>
                    &copy; 2023 Science Rag
                </StyledSmallRight>
            </StyledSmallContainer>

        </StyledFooterWrapper>
    )
}

export { Footer }
