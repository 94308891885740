import styled from "styled-components";

export const widthConstants = {
    width: '100%',
    minWidth: '300px',
    maxWidth: '800px',
}

export const editorBody = {
    height: '200px',
}

export const CenteredWrapperDiv = styled.div`
margin: auto;
width: ${widthConstants.width};
min-width: ${widthConstants.minWidth};
max-width: ${widthConstants.maxWidth};

`;
export const StyledForm = styled.form`
    margin: 20px;

    input {
        display: block;
        margin-bottom: 20px;
    }

    textarea {
        rows: 4;
        width: 100%;
    }

    label {
        display: block;
    }
`;

export const bodyContainerStyle = {
    ...widthConstants
}

export const bodyEditorStyle = {
    border: '1px solid #cccccc',
    ...widthConstants,
}

export const bodyTextAreaStyle = {
    ...widthConstants,
    margin: 'auto',
    display: 'block',
}
