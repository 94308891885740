import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import styled from "styled-components";
import { LOGO_URL } from '../constants';
import { setTagName } from './TopicPage';

const StyledDiv = styled.div`
    max-width: 600px;
    margin: 20px auto;
`;

const StyledLogo = styled.img`
width: 40px;
height: 40px;
vertical-align: bottom;
`;

const TAGS_QUERY = gql`
query AllTags {
    allTags {
        edges {
            node {
                name
                articles {
                    edges {
                        node {
                            id
                            isPublished
                        }
                    }
                }
            }
        }
    }
}
`;
const Submissions = () => {
    const options = {
        variables: {
            isPublished: true,
            sort: "CREATED_AT_DESC",
        },
    }
    const { data, error, loading } = useQuery(TAGS_QUERY, options);
    
    useEffect(() => {
        document.title = "Submissions | Science Rag";
      }, []);


    if (loading)
      return null
    if (error) {
        console.log(JSON.stringify(error, null, 2));
    }

    const currentlySeekingTopics = () => {
        const articleCountByTags: {[key: string]: number}[] = data.allTags.edges.map(
            ({ node: tagNode } : {name: string, [key: string]: any}) => ({
                [tagNode.name]: tagNode.articles.edges.filter(
                    ({ node: articleNode }: {[key: string]: any}) => articleNode.isPublished
                ).length
            })
        );
    
        articleCountByTags.sort((e1: {[key: string]: any}, e2: {[key: string]: any}) => (Object.values(e1)[0] - Object.values(e2)[0]));

        const neededTopics: JSX.Element[] = [];
        let keyIndex = -1;
        let counter = 0;

        for (const rowData of articleCountByTags) {
            for (const key in rowData) {
                // length - 2 means the last two topics (those with the most content) are omitted from this list
                if (counter < articleCountByTags.length - 3) {
                    neededTopics.push(<li key={++keyIndex}>{setTagName(key)}</li>)
                }
            }
            counter++;
        }
        return neededTopics;
    }
    const neededTopics = (error && []) || currentlySeekingTopics();

    return (
        <StyledDiv>
            <h1>
                <StyledLogo src={LOGO_URL} alt='Erlenmeyer flask logo.' />
                Call for Submissions! 
            </h1>
            <p>
                <i>Science Rag</i> seeks out talented writers who are able to connect contemporary science news stories with well-formed prosaic comedy concepts. Contributors will receive $40 per article.
            </p>
            {
                !error &&
                <>
                    <p>
                        Topics for which <i>Science Rag</i> is seeking coverage include:
                    </p>
                    <ul>
                        {
                            (neededTopics.length > 0 && neededTopics) || 
                            <li>(no current topics in demand)</li>
                        }
                    </ul>
                </>
            }
            <p>
		If you'd like to submit a story, check out the requirements and instructions in
		the <a href="https://docs.google.com/document/d/1VYof6cMSn82KcldChzeg7UwanJk6X00ly55cIWihalA/edit"><i>Science Rag</i> Style Guide</a>.
            </p>
        </StyledDiv>
    );
}

export {Submissions}

