import { URL } from ".";

export const crossOrigin: 'use-credentials' = 'use-credentials';

// #region whitelisted tags
// a, b, br, del, em, i, ins, p, strong
// pattern: (?<b>.+)|(?<del>.+)
// a or b
// where a is first whitelisted tag
// where b is second whitelisted tag
// #endregion whitelisted tags
const regexAcceptableTags = /<\s*\/?\s*(?!(a|b|br|del|em|i|ins|li|ol|ul|p|strong))\b.*?>/gm;

const sanitizedBody: (body: string) => string = (body: string) => {
    let newBody: string;
    newBody = body.replaceAll(regexAcceptableTags, '&lt;$1&gt;');
    return newBody;
}

const fetchImage = async (method: string, formData: FormData) => {
    const response = await fetch(`${URL}/api/images`, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        credentials: 'include', // include, *same-origin, omit
        headers: {
        'Accept': 'multipart/form-data'
        },
        body: formData // body data type must match "Content-Type" header
    });
    
    return response.json();
}
// post get image
const getImage = async (path: string) => {
    const formData = new FormData();
    formData.append("path", path);
    await fetchImage('POST', formData);
}

// post create image
async function uploadImage(files: FileList | null | undefined, method: string, articleId: number) {
    const file = files?.[0];

    if (!file)
        return
    const formData = new FormData();

    await file.arrayBuffer()
        .then((arrayBuffer) => {
            let blob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type });

            formData.append("file", blob);
            formData.append("articleId", articleId.toString());
            
        })
        .then(async () => {
            return await fetchImage(method, formData);
        })
        .catch((e: any) => console.log(e)
        );
}

const getImages = (ids: string[], articles: {[key: string]: any}, setArticles: any, setIsLoaded: any) => {
    if (!articles) {
        setIsLoaded(true);
        return;
    }
    const formData = new FormData();
    formData.append(
        "articleIds",
        ids.join(',')
    );
    fetch(`${URL}/api/images`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        credentials: 'include', // include, *same-origin, omit
        headers: {
        'Accept': 'multipart/form-data'
        },
        body: formData // body data type must match "Content-Type" header
    })
        .then((response: any) => {
            return response.json();
        })
        .then((json: any) => {
            const updatedArticles = articles.map(
                (e: any) => 
                    ({
                        ...e,
                        imagePath: json[parseInt(e.id)]
                    })
            )
            setArticles(updatedArticles);
            setIsLoaded(true);
        })
        .catch((err: any) => {
            setIsLoaded(true);
            console.error(err);
        });
}

const handleDeleteNode = async (deleteNode: (args: any) => Promise<any>, nodeId: string, next: string) => {
    await deleteNode({ variables: { inputData: { id: nodeId }}})
        .then(
            () => document.location.pathname = next
        )
        .catch(
            (error) => {
                console.log(error);
            }
        );
}

const deleteNodeConfirmed = async (deleteNode: (args: any) => Promise<any>, e: any, node: any, next: string) => {
    e.preventDefault();
    await handleDeleteNode(deleteNode, node.id, next);
}

export {
    deleteNodeConfirmed,
    getImage,
    getImages,
    sanitizedBody,
    uploadImage,
}
